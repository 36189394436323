<template>
    <main>
        <Loader v-if="loading" />
        
        <div v-else>
            <!-- Header -->
            <header class="w-full relative" :style="bgHeader">
                <Logo fullScreen />
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl pl-5 2xl:px-0 mt-10 sm:mt-18 lg:mt-3">
                    <h1 class="md:px-14 lg:px-2 ml-2 block">
                        <span class="w-fit slanted sm:ml-4 px-4 lg:pl-5">5.000€ in 60 mesi</span>
                        <span class="w-fit slanted sm:ml-4 px-4 lg:pl-3 lg:pr-5">TAEG 6,50%*</span>
                    </h1>
                </div>
            </header>

            <form class="w-full mx-auto text-center px-6 lg:px-0" @submit.prevent="submit()">
                
                <div class="bg-white relative rounded-3xl shadow-2xl mx-auto pb-2 lg:pb-4 mb-5 -mt-18 sm:-mt-36 sm:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="px-4">
                        <p class="text-xl text-gray-5 font-semibold pt-6 px-4 text-2xl">{{ name }},</p>
                        <p class="text-lg mb-4 lg:mb-2 mt-1">scopri l'offerta pensata per te</p>
                        <div class="max-w-md mx-auto">
                            <div>
                                <select id="credit-select" class="home-select w-48 border-2 mt-3" v-model="choosenCredit" @change="activeSolutionIndex = $event.target.selectedIndex">
                                    <option v-for="price in prices" :key="price.imp_finanz" :value="price.imp_finanz">{{ price.imp_finanz|num }}€<span v-if="price.imp_finanz == promo">*</span></option>
                                </select>
                            </div>
                            <button class="w-full md:w-fit block rounded-full px-12 pb-2 pt-3 bg-green-1 text-white mt-2 shadow-xl mx-auto" type="submit">
                                <span class="block font-semibold text-xl leading-5 uppercase">Richiedi ora</span>
                                <span class="block leading-5 font-light text-base text-center">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
                            </button>
                        </div>
                        <p class="font-semibold text-xl mt-12 lg:mt-8 mb-2 lg:mb-0">Ti bastano 2 minuti</p>
                    </div>
                    <div class="flex flex-wrap items-start py-4 lg:pb-2 px-4 lg:px-40">
                        <!-- Icon 1 -->
                        <div class="w-1/3 flex flex-col items-center my-4">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon rata inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1">Rata <br class="sm:hidden" /> fissa</h4>
                        </div>
                        <!-- Icon 2   -->
                        <div class="w-1/3 flex flex-col items-center my-4">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon flash inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1">
                                Prevalutazione <br class="sm:hidden" />
                                immediata
                            </h4>
                        </div>
                        <!-- Icon 3 -->
                        <div class="w-1/3 flex flex-col items-center my-4">
                            <div class="bg-gray-1 rounded-full align-middle mb-1 sm:mt-0 aspect-square w-14 h-14 flex items-center justify-center">
                                <i class="icon zero inline-block w-full"></i>
                            </div>
                            <h4 class="block text-sm xl:inline-block lg:px-2 mt-1">
                                Zero spese <br class="sm:hidden" /> 
                                accessorie
                            </h4>
                        </div>
                    </div>
                </div>

                <section class="section-disclaimer mb-8 md:mb-5">
                    <div class="container text-center mx-auto mt-1">
                        <p class="w-full text-center text-gray-4 text-sm py-4">
                            *Messaggio pubblicitario con finalità promozionale - Offerta valida fino al {{ expFullDate }}
                            <svg @click="showPromoModal" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block ml-1 -mt-1 cursor-pointer w-3 h-3">
                                <circle cx="8.59766" cy="8.09766" r="7.40845" stroke="#252525" stroke-width="0.987793"/>
                                <path d="M8.00391 12.0494V6.6165H9.18827V12.0494H8.00391ZM8.00391 5.69292V4.44336H9.18827V5.69292H8.00391Z" fill="#252525"/>
                            </svg>
                        </p>
                    </div>
                </section>

            </form>

            <!-- Section vantaggi -->
            <section class="section-feat py-6 text-center cursor-pointer" @click="scrollToAnchor()">
                <div class="max-w-4xl lg:max-w-5xl xl:max-w-7xl mx-auto">
                    <div class="w-full relative flex flex-wrap items-center px-6">
                        <h3 class="w-3/4 flex-grow font-semibold text-lg text-left px-2">Scopri tutti i vantaggi del Prestito Personale pensato per te</h3>
                        <i class="icon arrowdown"></i>
                    </div>
                </div>
            </section>

            <!-- Section steps -->
            <section class="section-steps bg-gray-5 py-6 sm:py-10 lg:py-8">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-8 sm:mb-6">
                        <h3 class="font-semibold text-3xl text-white">
                            Come attivarlo?<br class="sm:hidden" />
                            Bastano pochi passaggi
                        </h3>
                    </div>
                    <!-- Steps -->
                    <div class="flex flex-wrap">
                        <!-- Step 1 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__1">
                                <span class="block font-bold text-sm text-gray-4">Step 1</span>
                                <p class="font-semibold text-3xl text-red-1">Invia la richiesta</p>
                                <p class="font-medium text-gray-4">Inserisci i tuoi dati. Bastano solo pochi minuti!</p>
                            </div>
                        </div>
                        <!-- Step 2 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto
                                animate__animated animateFadeInUp animate__2">
                                <span class="block font-bold text-sm text-gray-4">Step 2</span>
                                <p class="font-semibold text-3xl text-red-1">Ricevi l'esito</p>
                                <p class="font-medium text-gray-4">Prevalutazione immediata con esito in 24 ore</p>
                            </div>
                        </div>
                        <!-- Step 3 -->
                        <div class="text-center px-6 mb-6 w-full sm:w-1/2 sm:px-4 lg:w-1/3 lg:flex lg:flex-1">
                            <div class="w-full text-left bg-white rounded-3xl shadow-2xl pt-4 pb-6 px-6 sm:h-40 lg:h-auto animate__animated animateFadeInUp animate__4">
                                <span class="block font-bold text-sm text-gray-4">Step 3</span>
                                <p class="font-semibold text-3xl text-red-1">Firma con un sms</p>
                                <p class="font-medium text-gray-4">Firma la richiesta di finanziamento direttamente dal tuo smartphone</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section contest -->
            <ClubContest>
                <p class="text-base mt-4">
                    *Concorso valido dal 11/11/2023 al 30/10/2024. 
                    <a href="https://www.cofidis.it/it/download/trasparenza/Regolamento_Cofidis_Ti_Premia_2246_23_007.pdf" class="underline" target="_blank">Regolamento concorso</a>.
                </p>
            </ClubContest>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-6 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-semibold text-4xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <!-- Section trust -->
            <section id="anchor" class="section-feat2 py-6">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <h3 class="font-semibold text-3xl text-gray-5 leading-none mb-6 text-center">Cofidis vuol dire fiducia!</h3>
                    <p class="text-base font-medium px-4">Siamo <strong class="font-bold">il credito al consumo che dà e ispira fiducia immediata</strong> grazie a soluzioni semplici, veloci e 100% digitali in grado di
                        fornire un aiuto immediato nella vita delle persone.</p>
                    <p class="text-base font-medium px-4 mt-4">Proprio come abbiamo fatto con te, che <strong class="font-bold">ci hai già scelto</strong> per acquistare ciò che ami. Ora vogliamo darti ulteriore fiducia
                        con questa <strong class="font-bold">soluzione pensata per te.</strong></p>
                </div>
            </section>

            <!-- Section faq -->
            <Accordion v-if="prices.length" title="Come funziona?" :tabs="[
                {
                    title: 'Cosa serve per la richiesta di Prestito Personale', 
                    content: 'Per la richiesta di Prestito Personale inserisci i tuoi dati nel form. Ti bastano solo 2 minuti!'
                },
                {
                    title: 'Bastano pochi click e la prevalutazione è immediata', 
                    content: faqHtml
                }]" 
            />

            <a @click="submit()" id="sticky-cta" class="w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 z-50 cursor-pointer bg-green-1">
                <span class="block text-center text-xl text-white">RICHIEDI ORA</span>
                <span class="block font-light text-base text-center -mt-1 text-white">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
            </a>

        </div>
    </main>
</template>
<script>
// @ is an alias to /src
import Trustpilot from "@/components/TrustpilotSwiper"
import Loader from "@/components/Loader"
import Logo from "@/components/Logo"
import ClubContest from "@/components/ClubContest"
import Accordion from "@/components/Accordion"
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils"
import axios from "axios";
import moment from "moment/moment";

const redirectHomepage = () => {    
    location.href = '/pz'
}

const formatDate = date => {
    if (!date) return null
    return date.toString().length > 1 ? date : '0' + date
}

export default {
    name: "Home",
    components: { Loader, Trustpilot, Logo, ClubContest, Accordion },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_pz)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },
        scrollToAnchor() {
            window.scrollTo({
                top: document.getElementById('anchor').offsetTop,
                left: 0,
                behavior: "smooth",
            });
        },
        toggleFaq(i) {
            if (this.activeFaq == i) this.activeFaq = null;
            else this.activeFaq = i;
        },            
        submit() {
            window.removeEventListener('popstate', redirectHomepage)
            window.removeEventListener('scroll', this.scrollAnimations)

            this.$store.commit("setUserData", { key: 'importo', value: this.choosenCredit });
            this.$store.commit("setUserData", { key: 'rate', value: this.numInstallments });
            
            this.$store.dispatch("sendData", { step: "importo" });
            if (!this.document_expired && this.document_expire !== null  ) {
                this.$store.dispatch("sendData", { step: "dati_pers_4" })
            }
            if (this.choosenCredit == this.promo) {
                this.$store.dispatch("redirect", { step: "homepage", route: "RataPz" });
            } else {
                this.$store.dispatch("redirect", { step: "homepage", route: "PrecompiledData" });
            }
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
        scrollAnimations() {
            // steps animation
            const animateFadeInUp = document.getElementsByClassName("animateFadeInUp");
            const windowOffsetTop = window.innerHeight + window.scrollY;

            Array.prototype.forEach.call(animateFadeInUp, (animatedBox) => {
                const animatedBoxOffsetTop = animatedBox.offsetTop;
                if (windowOffsetTop >= animatedBoxOffsetTop) {
                    animatedBox.classList.add('animate__fadeInUp')
                }
            });

            // sticky cta
            if ( window.scrollY > window.innerHeight / 1.5 && !this.showStickyCta || window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
                document.querySelector('#sticky-cta').classList.toggle('show')
                this.showStickyCta = ! this.showStickyCta 
            }
        }
    },
    computed: {
        ...mapGetters(["uniquePrices","promoKO"]),
        ...mapUserData(["name", "document_expired", "document_expire"]),
        taeg() {
            return this.prices[this.activeSolutionIndex].taeg
        },
        tan() {
            return this.prices[this.activeSolutionIndex].tan
        },
        rata() {
            return this.prices[this.activeSolutionIndex].imp_rata
        },
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") 55% 0% no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        numInstallments() {
            return this.prices[this.activeSolutionIndex].rate
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        },
        secciUrl() {
            if (this.choosenCredit && this.choosenCredit === this.promo) {
                const promoModel = this.getPromoOffer(false);
                return promoModel.secciUrl
            }
            else if (this.choosenCredit) {
                const baseUrl = this.$store.state.secciUrl
                const imp_finanz = this.prices[this.activeSolutionIndex].imp_finanz
                const rate = this.prices[this.activeSolutionIndex].rate
                return `${baseUrl}${imp_finanz}_${rate}.pdf`
            }
            else
                return ``
        },
        prices() {
            const arr = this.$store.state.prices
            return arr.reverse()
        },
        expItaDate() {
            return moment(this.expDate).locale("it").format('D MMMM')
        },
        expFullDate() {
            return moment(this.expDate).locale("it").format('DD/MM/Y')
        },
        faqHtml() {
            let html = `Puoi richiedere il Prestito Personale ovunque ti trovi e le condizioni sono chiare e trasparenti: 
                        TAN fisso del ${this.tan}% e TAEG del ${this.taeg}%. 
                        Restituisci l’importo in ${this.prices[this.activeSolutionIndex].rate} piccole rate da ${this.rata}€ (<a href='${this.secciUrl}' class='underline' target='_blank' >SECCI</a>). 
                        Ricevi una prima valutazione immediata e firmi digitalmente online la richiesta di Prestito Personale.`

            if (this.choosenCredit == this.$store.state.promo) {
                html +=  ` Le condizioni economiche dell’offerta promozionale possono variare in base al profilo del richiedente. 
                    Se il profilo non soddisfa i requisiti, il TAEG proposto sarà ${this.promoKO.taeg}% 
                    (<a href="${this.promoKO.secciUrl}" class="underline" target="_blank">SECCI</a>).`
            }

            return html;
        }
    },
    data() {
        return {
            loading: true,
            expDate: null,
            activeFaq: null,
            activeSolutionIndex: 0,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/pz",
            commonDistributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/common",
            showStickyCta: false
        };
    },
    async created() {        
        this.reloadOnBrowserBack()

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('pezzotto_user_id')
            codeCampaign = localStorage.getItem('pezzotto_code_campaign')
            trackCode = localStorage.getItem('pezzotto_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        if (this.$store.state.urlVars.dev == 1) {
            this.expDate = 1735516800000
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });
            this.$store.state.serverUrl = null
        } else {
            try {
                localStorage.setItem('pezzotto_user_id', userId)
                localStorage.setItem('pezzotto_code_campaign', codeCampaign)
                localStorage.setItem('pezzotto_track_code', trackCode)
                
                const data = await this.loadClientData({userId, codeCampaign, trackCode})            
                
                this.$store.commit("setUserData", { key: 'user_id', value: userId });
                this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });
                this.$store.commit("setUserData", { key: 'client_id', value: data.cliente_id });
                
                this.$store.commit("setUserData", { key: 'piano_f', value: data.pianof_pz });
                this.$store.commit("setUserData", { key: 'name', value: data.Nome });
                this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
                this.$store.commit("setUserData", { key: 'email', value: data.EMail });
                this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
                this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
                this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
                this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });

                // address
                this.$store.commit("setUserData", { key: 'address', value: data.Indirizzo });
                this.$store.commit("setUserData", { key: 'address_city', value: `${data.City} (${data.Prov})`});
                this.$store.commit("setUserData", { key: 'address_number', value: data.N });
                this.$store.commit("setUserData", { key: 'address_cap', value: data.CAP });
                this.$store.commit("setUserData", { key: 'citizenship', value: data.cittadinanza });
                this.$store.commit("setUserData", { key: 'same_addresses', value: true });
                
                // document
                const expireDate = [formatDate(data.Doc_Data_End_Day), formatDate(data.Doc_Data_End_Month), data.Doc_Data_End_Year]
                if(expireDate[0] && moment(expireDate.join('/'), "DD-MM-YYYY") < moment()) {
                    this.$store.commit("setUserData", { key: 'document_expired', value: true });
                }
                else {
                    this.$store.commit("setUserData", { key: 'document_expired', value: false });
                    this.$store.commit("setUserData", { key: 'identity_type', value: data.Doc_Type });
                    this.$store.commit("setUserData", { key: 'document_number', value: data.Doc_Number });
                    this.$store.commit("setUserData", { key: 'document_city', value: `${data.Doc_City} (${data.Doc_Prov})` });
                    
                    const releaseDate = [formatDate(data.Doc_Data_Day), formatDate(data.Doc_Data_Month), data.Doc_Data_Year]
                    this.$store.commit("setUserData", { key: 'document_release', value: releaseDate.join('/') });

                    if (expireDate[0])
                        this.$store.commit("setUserData", { key: 'document_expire', value: expireDate.join('/') });
                    else 
                        this.$store.commit("setUserData", { key: 'document_expire', value: null });
                }

                // creditline
                this.$store.commit("setUserData", { key: 'is_creditline', value: +data.flag_creditline });

                // set expiry date
                this.expDate = data.dt_end_pz
                this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

                // offer expired
                if(moment(data.dt_end_pz).endOf('day') < moment()) {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                    return;
                }
                // pending procedure
                if(data.page_block && data.page_block === 'evaluation') {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                    return;
                }
                // failed procedure
                if(data.page_block && data.page_block === 'reddito_not_allowed') {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                    return;
                }                  

                // init robot
                this.$store.dispatch("sendData", { step: "enter_landing" });

            } catch (error) {
                // reload from thank you page
                if(localStorage.getItem('pezzotto_request_evaluation')) {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                }
                else {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                }
            }
        }

        this.$store.commit("setChoosenCredit", this.$store.state.promo)
        this.activeSolutionIndex = this.prices.findIndex(obj => obj.imp_finanz == this.choosenCredit)
        this.loading = false;        
    },
    mounted() {
    
        if ( window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
            document.querySelector('#sticky-cta').classList.add('show')
        }

        window.addEventListener('scroll', this.scrollAnimations, {passive: true} )
    }
};
</script>
<template>
    <main>
        <Loader v-if="loading" />
        
        <div v-else>

            <!-- Header -->
            <header class="w-full relative pb-4" :style="bgHeader">
                <Logo :fullScreen="true" />
                <div class="w-full flex justify-center">
                    <div class="w-full frame mt-18 md:mt-0">
                        <div class="spacer">
                            <div class="absolute -mt-28 md:mt-0">
                                    <h1 class="mt-4 md:ml-4">
                                        <span class="w-fit slanted px-4 lg:pl-5">Per te che hai amato</span>
                                        <span class="w-fit slanted px-4 lg:pl-3 lg:pr-5">pagare a rate con PagoDIL</span>
                                    </h1>
                            </div>
                        </div>
                        <div class="bg-white pt-3 pb-3">
                            <h2 class="text-center font-semibold text-5xl">{{ choosenCredit|num }}€</h2>
                            <div class="flex justify-center items-center pt-1">
                                <p class="text-lg pr-2">Alla <strong class="font-semibold">stessa rata</strong> di</p>
                                <img src="@/views/themes/top-up/images/pagodil-logo.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="absolute w-full flex justify-center mt-5 md:mt-0">
                    <button class="w-fit block rounded-full px-12 pb-2 pt-3 bg-green-1 text-white shadow-xl mx-auto transform -translate-y-2" type="submit" @click="submit">
                        <span class="block font-semibold text-xl leading-5 uppercase">Richiedi ora</span>
                        <span class="block leading-5 font-light text-base text-center">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
                    </button>
                </div>
            </header>

            <!-- Section esempio -->
            <section class="bg-white pt-18 md:pt-32 text-center">
                <h2 class="text-2xl lg:text-3xl font-semibold px-4">Nuova liquidità, stessa comodità</h2>
                <p class="mt-4 lg:mt-6 text-base lg:text-lg px-6">Mantieni le tue abitudini: continua a pagare la stessa rata* di PagoDIL e richiedi {{ choosenCredit|num }}€ <strong class="font-semibold">da spendere come vuoi.</strong></p>
                <div class="mx-auto w-fit mt-8">
                    <Phone :importo="choosenCredit" :rata="imp_rata_orig" ></Phone>
                </div>
                <div class="bg-gray-200 w-full shadow border-t border-gray-300">
                    <div class="container py-2 px-4 mx-auto sm:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                        <p class="text-sm text-left">*La rata del Prestito Personale potrebbe differire fino ad un massimo del 10% dalla rata di PagoDIL, a seconda dell’offerta proposta.</p>
                    </div>
                </div>            
            </section>

            <!-- Section come funziona -->
            <section class="section-passages bg-white pt-6 pb-14">
                <div class="sm:container p-6 mx-auto">
                    <h3 class="font-bold text-3xl text-gray-5 leading-none mb-8 text-center">
                        Come funziona<br>
                    </h3>
                    <div class="relative w-full xl:w-2/3 mx-auto">
                        <div class="absolute h-4/5 top-2 left-4 sm:top-0 sm:h-full w-0.5 bg-yellow  z-0"></div>
                            
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">1</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-semibold my-auto">Clicca su Richiedi e inserisci le informazioni necessarie</p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center ">
                                <span class="align-middle font-semibold">2</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-semibold my-auto">Ti inviamo il contratto via e-mail e lo firmi con un codice che ricevi via sms</p>
                            </div>
                        </div>
                        <div class="relative w-full flex mb-6 z-1">
                            <div class="bg-white w-8 h-8 mr-5 border border-solid border-yellow rounded-full text-center">
                                <span class="align-middle font-semibold">3</span>
                            </div>
                            <div class="w-10/12 flex">
                                <p class="text-base font-semibold my-auto">Ricevi {{ choosenCredit|num }}€ sul tuo conto corrente mantenendo la rata che avevi con PagoDIL*</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Section vantaggi -->
            <section class="bg-gray-200 px-4">
                <div class="bg-yellow rounded-2xl px-9 py-8 transform -translate-y-12 mx-auto sm:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <div class="lg:flex flex-wrap h-full">
                        <div class="text-icon">
                            <p class="mr-12 lg:mr-0 max-w-2xs lg:text-center">
                                <strong class="font-semibold">Richiedi</strong> comodamente <strong class="font-semibold">dal tuo smartphone...</strong>
                            </p>
                            <div class="bg-white rounded-full p-3 lg:mb-3">
                                <div class="icon phone mx-auto w-fit"></div>
                            </div>
                        </div>
                        <div class="text-icon mt-8 lg:mt-0">
                            <p class="mr-12 lg:mr-0 max-w-xs lg:text-center lg:px-8">
                                Nel momento della giornata in cui ti è <strong class="font-semibold">più comodo.</strong>
                            </p>
                            <div class="bg-white rounded-full p-3 lg:mb-3">
                                <div class="icon clock mx-auto w-fit"></div>
                            </div>
                        </div>
                        <div class="text-icon mt-8 lg:mt-0">
                            <p class="mr-8 lg:mr-0 max-w-2xs lg:text-center">
                                <strong class="font-semibold">Non sono necessari documenti</strong> di reddito!
                            </p>
                            <div class="bg-white rounded-full p-3 lg:mb-3">
                                <div class="icon nodocument mx-auto w-fit"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <!-- Section widget -->
            <section class="section-widget md:mt-0 py-12 sm:py-10 lg:py-8 bg-white">
                <div class="container mx-auto max-w-4xl lg:max-w-5xl xl:max-w-7xl">
                    <!-- Title -->
                    <div class="text-center mb-4 sm:mb-8">
                        <h3 class="font-bold text-3xl text-gray-5 leading-none">
                            Cosa dicono i <br class="sm:hidden" />
                            nostri clienti
                        </h3>
                    </div>
                    <Trustpilot />
                </div>
            </section>

            <!-- Section faq -->
            <section class="bg-yellow">
                <Accordion title="Costi chiari" :tabs="[
                {
                    title: 'Il Prestito Personale pre-approvato e senza busta paga', 
                    content: `Il Prestito Personale pre-approvato è il prodotto finanziario riservato ai nostri migliori clienti. 
                        Le condizioni sono chiare e trasparenti: TAN fisso del ${ $options.filters.num2(priceModel.tan) }% e TAEG del ${ $options.filters.num2(priceModel.taeg) }%. 
                        Restituisci l’importo in ${ priceModel.rate } piccole rate da ${ $options.filters.num2(priceModel.imp_rata) } €.  Non ci sono spese di apertura pratica. 
                        Per richiederlo ti bastano pochi click ovunque ti trovi, senza bisogno di fornire i documenti di reddito.`
                }]" 
                />
            </section>

            <a @click="submit()" id="sticky-cta" class="w-4/6 sm:w-auto font-semibold text-lg text-gray-4 bg-orange-1 max-w-xs rounded-full shadow-2xl px-6 sm:px-10 py-2 sm:max-w-none fixed bottom-6 left-1/2 z-50 cursor-pointer bg-green-1">
                <span class="block text-center text-xl text-white">RICHIEDI ORA</span>
                <span class="block font-light text-base text-center -mt-1 text-white">Solo fino al <strong class="font-bold uppercase">{{ expItaDate }}</strong></span>
            </a>

        </div>

    </main>
</template>
<script>
// @ is an alias to /src
import Trustpilot from "@/components/TrustpilotSwiper"
import Loader from "@/components/Loader"
import Logo from "@/components/Logo"
import Accordion from "@/components/Accordion"
import loadData from "@/dataService.js";
import { mapGetters } from "vuex";
import { mapUserData } from "@/utils";
import axios from "axios";
import moment from "moment/moment";
import Phone from "./Phone.vue";

const redirectHomepage = () => {    
    location.href = '/top-up'
}

export default {
    name: "Home",
    components: { Loader, Trustpilot, Logo, Accordion, Phone },
    methods: {
        async loadClientData(data) {
            return new Promise((resolve, reject) => {
                axios.post(this.crmUrl, data)
                    .then(function(response) {
                        if(response.data && response.data.dt_end_top)
                            resolve(response.data)
                        else
                            reject(false)
                    })
                    .catch(function() {
                        reject(false)
                    })
            })
        },       
        async submit() {
            window.removeEventListener('popstate', redirectHomepage)

            this.$store.dispatch("sendData", { step: "dati_anag" });

            this.$store.dispatch("redirect", { step: "homepage", route: "IncomeTopUp" });
        },
        reloadOnBrowserBack() {            
            window.popStateDetected = false
            window.addEventListener('popstate', redirectHomepage)
        },
        scrollAnimations() {
            // sticky cta
            const stickyEl = document.querySelector('#sticky-cta');
            if ( stickyEl && window.scrollY > window.innerHeight / 1.5 && !this.showStickyCta || window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
                document.querySelector('#sticky-cta').classList.toggle('show')
                this.showStickyCta = ! this.showStickyCta 
            }
        }
    },
    computed: {
        ...mapGetters(["uniquePrices"]),
        ...mapUserData(["exp_date", "rate"]),
        choosenCredit: {
            get() {
                return this.$store.state.choosenCredit;
            },
            set(val) {
                this.$store.commit("setChoosenCredit", val);
            }
        },
        bgHeader() {
            const env = process.env.VUE_APP_ENV == "production" ? 'prod' : 'stage'
            return {
                '--bg-header': `#fff url("${this.distributionUrl}/${env}/header.jpg") center top no-repeat`,
                '--bg-header-sm': `#fff url("${this.distributionUrl}/${env}/header-sm.jpg") center top no-repeat`,
            }
        },
        crmUrl() {
            return this.$store.state.crmUrl;
        },
        expItaDate() {
            return moment(this.exp_date).locale("it").format('D MMMM')
        },
        expFullDate() {
            return moment(this.exp_date).locale("it").format('D/M/Y')
        },
        secciUrl() {
            const credit = this.$store.state.choosenCredit
            const baseUrl = this.$store.state.secciUrl
            if (credit)
                return `${baseUrl}Secci${credit.toString()[0]}k.pdf`
            else
                return ``
        }
    },
    data() {
        return {
            loading: true,
            expDate: null,
            prices: loadData("price.json").prices,
            priceModel: {},
            imp_rata_orig: null,
            distributionUrl: "https://d4ybvu6hnmspp.cloudfront.net/assets_front/top-up",
        };
    },
    async created() {        

        // get params
        const urlParts = this.$store.state.urlParts
        let userId, codeCampaign, trackCode;

        if(urlParts[1] && urlParts[2]) {
            userId = urlParts[1]
            codeCampaign = urlParts[2]
            trackCode = urlParts[3] ?? null
        }
        else {
            userId = localStorage.getItem('topup_user_id')
            codeCampaign = localStorage.getItem('topup_code_campaign')
            trackCode = localStorage.getItem('topup_track_code') ?? null
            // fix localstorage cast type issue
            if(trackCode === "null") trackCode = null;
        }

        try {
            localStorage.setItem('topup_user_id', userId)
            localStorage.setItem('topup_code_campaign', codeCampaign)
            localStorage.setItem('topup_track_code', trackCode)

            const data = await this.loadClientData({userId, codeCampaign, trackCode})            
            
            this.$store.commit("setUserData", { key: 'user_id', value: userId });
            this.$store.commit("setUserData", { key: 'code_campaign', value: codeCampaign });

            this.$store.commit("setUserData", { key: 'iniz', value: data.Iniz });
            this.$store.commit("setUserData", { key: 'piano_f', value: data.PianoF });
            this.$store.commit("setUserData", { key: 'name', value: data.Nome });
            this.$store.commit("setUserData", { key: 'surname', value: data.Cognome });
            this.$store.commit("setUserData", { key: 'email', value: data.EMail });
            this.$store.commit("setUserData", { key: 'iban', value: data.Iban });
            this.$store.commit("setUserData", { key: 'fiscal_code', value: data.CF });
            this.$store.commit("setUserData", { key: 'cellphone', value: data.Phone });
            this.$store.commit("setUserData", { key: 'day_in_month', value: 1 });
            this.$store.commit("setUserData", { key: 'is_creditline', value: +data.flag_creditline });
            this.$store.commit("setChoosenCredit", data.finanziato_top );
            this.$store.commit("setUserData", { key: 'rate', value: data.numero_rate_top });
            this.$store.commit("setUserData", { key: 'importo', value: data.finanziato_top });
            this.imp_rata_orig = data.imp_rata_orig_top

            // address
            this.$store.commit("setUserData", { key: 'address', value: data.Indirizzo });
            this.$store.commit("setUserData", { key: 'address_city', value: `${data.City} (${data.Prov})`});
            this.$store.commit("setUserData", { key: 'address_cap', value: data.CAP });

            this.priceModel = this.prices.find( obj => { return obj.imp_finanz == this.choosenCredit && obj.rate == this.rate } )

            // set expiry date
            this.expDate = data.dt_end_top
            this.$store.commit("setUserData", { key: 'exp_date', value: this.expDate });

            // offer expired
            if(moment(data.dt_end_top).endOf('day') < moment()) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestExpired"});
                return;
            }

            // pending procedure
            if(data.page_block && data.page_block === 'evaluation') {
                    this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
                return;
            }

            // failed prodecure with metlife
            if(data.page_block && data.page_block === 'reddito_not_allowed' && sessionStorage.getItem('topup_typ_metlife') == 1) {
                this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
                return;
            }

            // failed procedure
            if(data.page_block && data.page_block === 'reddito_not_allowed') {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
                return;
            }

            // check expired document
            if(moment(data.data_scadenza_documento) < moment()) {
                this.$store.commit("setUserData", { key: 'document_expired', value: true });
            }
            else {
                this.$store.commit("setUserData", { key: 'document_expired', value: false });
            }                        

            // init robot
            this.$store.dispatch("sendData", { step: "enter_landing" });

            // reset local storage for new user
            localStorage.removeItem('topup_ko_club')

        } catch (error) {
            // reload from thank you page
            if(localStorage.getItem('topup_request_evaluation') && sessionStorage.getItem('topup_typ_metlife') == 1) {
                this.$store.dispatch("redirect", {step: "income", route: "TypMetlife"});
            } 
            else if (localStorage.getItem('topup_request_evaluation')) {
                this.$store.dispatch("redirect", {step: "income", route: "RequestPending"});
            }
            else {
                this.$store.dispatch("redirect", {step: "income", route: "RequestKO"});
            }
        }                
        this.loading = false;
    },
    mounted() {     
        const stickyEl = document.querySelector('#sticky-cta')
        if ( stickyEl && window.scrollY <= window.innerHeight / 1.5 && this.showStickyCta ) {
            stickyEl.classList.add('show')
        }

        window.addEventListener('scroll', this.scrollAnimations, { passive: true } )
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.scrollAnimations)
    }
};
</script>